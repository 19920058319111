import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tabs,
  Tab,

} from "@mui/material";
import { Amount, StandardTable } from "standard";
import { walletList, rateList } from "../../utils/request";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography component={'div'}>{children}</Typography>
      )}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AccountPage() {
  return (
    <StandardTable
      columns={[
        { headerName: '货币', field: 'currency', },
        { headerName: '余额', field: 'balance', },
        { headerName: '可用余额', field: 'availableBalance', },
        {
          headerName: '今日收入', field: 'todayIncome',
          renderCell: (params) => <Amount value={params.value} />,
        },
        {
          headerName: '今日支出', field: 'todayExpend',
          renderCell: (params) => <Amount value={params.value} sub />,
        },
      ]}
      getRowId={(row) => `${row.createTIme}${row.balance}`}
      query={(params) => walletList(params)}
    />
  );
}

function RatePage() {
  return (
    <StandardTable
      columns={[
        { headerName: '模式', field: 'name', },
        { headerName: '货币', field: 'currency', },
        { headerName: '最低金额', field: 'amountMin', },
        { headerName: '最高金额', field: 'amountMax', },
        { headerName: '费率', field: 'rate', },
        { headerName: '费用', field: 'fee', },

      ]}
      getRowId={(row) => `${row.name}${row.currency}`}
      query={(params) => rateList(params)}
      notFoundSrc='/no_items_found.png'
    />
  );

}


export default function WalletPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="钱包" {...a11yProps(0)} />
          <Tab label="费率" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AccountPage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <RatePage />
      </CustomTabPanel>
    </>
  );
}

